import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  VideoJSQualityPlugin,
  VideoJSIVSTech,
  registerIVSQualityPlugin,
  registerIVSTech,
  VideoJSEvents,
  MetadataEventType,
} from 'amazon-ivs-player';
import videojs from 'video.js'
import { useEventListener } from './hooks'
import { IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { makeStyles } from '@mui/material/styles';
import * as workerTimers from 'worker-timers'
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!style-loader!css-loader!video.js/dist/video-js.css'
import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';

const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();


const PREFIX = 'IVSVideoPlayer';

const classes = {
  video: `${PREFIX}-video`,
  player: `${PREFIX}-player`,
  customOverlay: `${PREFIX}-customOverlay`,
  fullscreenButton: `${PREFIX}-fullscreenButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ aspectRatio, wmOpacity }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  [`& .${classes.video}`]: {
    maxWidth: aspectRatio ? `${1 / aspectRatio * 100}vh` : 0,
    maxHeight: aspectRatio ? `${aspectRatio * 100}vw` : 0,
    width: aspectRatio ? '100%' : 0,
    height: aspectRatio ? '100%' : 0,
    flex: 1
  },
  [`& .${classes.player}`]: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  [`& .${classes.customOverlay}`]: {
    // color: 'white !important',
    backgroundColor: 'transparent !important',
    height: `100% !important`,
    width: `100% !important`,
    marginTop: 'auto !important',
    marginBottom: 'auto !important',
    padding: '0 !important',
    top: '0 !important',
    left: '0 !important',
    marginLeft: '0 !important',
    // display: 'flex !important',
    display: 'block !important',
    position: 'absolute !important',
    // alignItems: 'center !important',
    // justifyContent: 'center !important',
    opacity: (wmOpacity && `${wmOpacity / 100} !important`) || '0.15 !important',
    // fontSize: 'calc(9px + (140 - 9) * ((100vw - 150px) / (2400 - 150))) !important',
    fontSize: '1em',
    userSelect: 'none !important',
    MozUserSelect: 'none !important',
    WebkitUserSelect: 'none !important',
    msUserSelect: 'none !important',
    // transform: 'translateY(-50%) rotate(-20.5deg) !important',
    // pointerEvents: 'none !important'
  },
  [`& .${classes.fullscreenButton}`]: {
    // position: 'absolute',
    // bottom: 0,
    // right: 0,
    color: 'white',
    zIndex: 2,
    opacity: 0.25,
    '&:hover': {
      opacity: 1
    },
    mixBlendMode: 'difference'
  }
}));

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const DEFAULT_STREAM =
  "https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8";


const overlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%' viewBox='0 0 200 100'>    
      <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
              <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
              <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
              <feGaussianBlur in="map" stdDeviation="0.15" />
      </filter>
      <text x="50%" y="50%" transform="rotate(-${Math.atan(height / width) * 180 / Math.PI}, 100, 50)" filter="url(#filter)" textLength="200" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
    </svg>
  </svg>`
}

const tiledOverlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%'>
      <defs>
        <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
          <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
          <feGaussianBlur in="map" stdDeviation="${height / 2000} ${width / 2000}" />
        </filter>
        <pattern id="p1" height='25%' width='50%' style="font-size: ${height / 22}px" patternUnits="userSpaceOnUse" patternTransform="rotate(-18.43494882292201)">    
          <text x="25%" y="12.5%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
        </pattern>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#p1)" filter="url(#filter)"></rect>
    </svg>
  </svg>`
}

const IVSVideoPlayer = React.memo(({ videoRef, fluid, fill, fullscreen, allowFullscreen, onTimeUpdate, isFullscreen, toggleFullScreen, videoId, onStarted, onMetadataLoaded, onVideoLoaded, onBuffer, onPlay, onVisiblityChange, wm, wmOpacity, onFinished, onVideoError, isLive, liveStreamStatus, videoStopTime, hasOnScreenDial, watermarkStyle }) => {
  const [aspectRatio, setAspectRatio] = useState()
  const playerRef = useRef(null);
  const videoNodeRef = useRef()
  const syncRef = useRef(0)


  const overlayFn = useMemo(() => {
    return watermarkStyle === 'Tiled' ? tiledOverlay : overlay
  }, [watermarkStyle])


  useEffect(() => {
    // Register IVS tech and plugin
    registerIVSTech(videojs, {
      wasmWorker: createAbsolutePath(wasmWorkerPath),
      wasmBinary: createAbsolutePath(wasmBinaryPath),
    });
    registerIVSQualityPlugin(videojs);
    
    const videoElement = videoRef.current;

    // Initialize the Video.js player with IVS configuration
    playerRef.current = videojs(videoNodeRef.current, {
      techOrder: ["AmazonIVS"],
      // controls: true,
      autoplay: true
    }, () => {
      playerRef.current.currentStreamTime = () => {
        return syncRef.current + playerRef.current.currentTime() // stream offset
        // return syncRef.current + (playerRef.current.currentTime() * 1000) // timestamp
      }
      const events = playerRef.current.getIVSEvents();
      const ivsPlayer = playerRef.current.getIVSPlayer();
      ivsPlayer.addEventListener(events.PlayerEventType.INITIALIZED, () => {
        console.log('IVS Player INITIALIZED')
        if (videoRef) videoRef.current = playerRef.current
        playerRef.current.overlay({
          content: '',
          class: classes.customOverlay,
          overlays: [{
            align: "center",
            content: overlayFn(wm, ivsPlayer.getDisplayHeight(), ivsPlayer.getDisplayWidth(), Math.floor(Math.random() * 10000000)),
            start: 'play',
            end: 'ended'
          }]
        });
        setAspectRatio(ivsPlayer.getDisplayHeight() / ivsPlayer.getDisplayWidth())
        if (onMetadataLoaded) onMetadataLoaded(ivsPlayer.getDisplayHeight() / ivsPlayer.getDisplayWidth())
      });
      ivsPlayer.addEventListener(events.PlayerEventType.SYNC_TIME_UPDATE, (e) => {
        playerRef.current.overlay({
          content: '',
          class: classes.customOverlay,
          overlays: [{
            align: "center",
            content: overlayFn(wm, ivsPlayer.getDisplayHeight(), ivsPlayer.getDisplayWidth(), Math.floor(Math.random() * 10000000)),
            start: 'play',
            end: 'ended'
          }]
        });
        if (onTimeUpdate) onTimeUpdate({
          target: { currentTime: playerRef.current.currentStreamTime() }
        });
      });
      ivsPlayer.addEventListener(events.PlayerState.ENDED, () => {
        if (onFinished) onFinished()
      });
      ivsPlayer.addEventListener(events.PlayerState.PLAYING, () => {
        playerRef.current.muted(false)
        if (onStarted) onStarted()
        if (onPlay) onPlay()
      });
      ivsPlayer.addEventListener(events.PlayerState.IDLE, () => {
        playerRef.current.play()
      });
      ivsPlayer.addEventListener(events.PlayerState.BUFFERING, () => {
        // console.log('IVS Player is buffering')
      });
      ivsPlayer.addEventListener(MetadataEventType.ID3, (event) => {
        if (!syncRef.current) { // TO DO: HANDLE START FROM BEGINNING
          const segmentMetadata = event.find(tag => tag.desc === 'segmentmetadata');
          const segmentInfo = JSON.parse(segmentMetadata.info[0]);
          console.log('segmentInfo:', segmentInfo);
          syncRef.current = segmentInfo['stream_offset'] //segmentInfo['transc_s'];
        }
      });
      playerRef.current.src(videoId)
      // playerRef.current.play()
    });

    playerRef.current.enableIVSQualityPlugin();

    // return () => {
    //   if (playerRef.current) {
    //     playerRef.current.dispose()
    //   }
    // }
  }, [videoId, isLive, liveStreamStatus, videoRef, onFinished, onBuffer, onMetadataLoaded, onPlay, onStarted, onVideoError, wm])

  // useEffect(() => {
  //   // if (!isPlayerSupported) {
  //   //   console.error("Amazon IVS Player is not supported on this browser.");
  //   //   return;
  //   // }



  // }, [videoId, isLive, liveStreamStatus, videoRef, onFinished, onBuffer, onMetadataLoaded, onPlay, onStarted, onVideoError]);

  return (
    (<Root aspectRatio={aspectRatio} wmOpacity={wmOpacity}>
      <div data-vjs-player style={{height:'95%', width: '100%'}} className={classes.video}>
        <video ref={videoNodeRef} className='video-js'></video>
      </div>
      {(allowFullscreen && !isFullscreen) && <div style={{ position: 'absolute', maxHeight: '100vw', height: aspectRatio ? `calc(${100 * 1 / aspectRatio}%)` : '100vw', maxWidth: '100vh', width: aspectRatio ? `calc(${100 * aspectRatio}%)` : '100vh', transform: hasOnScreenDial ? 'translate(0,50%) rotate(270deg) translate(50%,0)' : 'rotate(270deg)' }}>
        <IconButton className={classes.fullscreenButton} color="inherit" onClick={toggleFullScreen}>
          {/* {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} */}
          <FullscreenIcon />
        </IconButton>
      </div>}
    </Root>)
  );
})

export default IVSVideoPlayer;
